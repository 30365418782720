<template>

  <div class="service_form_details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="4"
        lg="4"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ customerDetail.stringID }} - {{ customerDetail.name }}
            </h1>
            <p class="log_info">
              Created by {{ getCreatedBy() }} on {{ getCreatedAt() }}<br>Last updated on {{ getUpdatedAt() }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right mb-1"
        md="8"
        lg="8"
        cols="12"
      >
        <b-button
          v-if="canViewThisAction('update', 'Customer')"
          variant="light"
          class="text-primary"
          :to="{ name: 'customers-customers-edit', params: { id: customerDetail._id || 0 } }"
        >
          <feather-icon
            icon="EditIcon"
            size="16"
          />
          Edit
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card class="agent__details">
      <b-row>
        <b-col md="12">
          <b-card
            class="has-padding"
          >
            <b-card-title>
              Customer Details
            </b-card-title>
            <b-card-body>
              <b-row>
                <b-col md="6">
                  <b-table-simple
                    borderless
                    responsive
                    class="particulars_of_deceased d-none d-md-block"
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Customer Name
                        </b-th>
                        <b-td>{{ customerDetail.name }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          NRIC
                        </b-th>
                        <b-td>{{ customerDetail.nric }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Date of Birth
                        </b-th>
                        <b-td>{{ customerDetail.dob ? dateFormat(customerDetail.dob) : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Sex
                        </b-th>
                        <b-td>{{ customerDetail.sex || '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Race
                        </b-th>
                        <b-td>{{ customerDetail.race || '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Nationality
                        </b-th>
                        <b-td>{{ customerDetail.nationality || '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Nirvana SG User
                        </b-th>
                        <b-td>{{ customerDetail.emailVerified ? 'Yes' : 'No' }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
                <b-col md="6">
                  <b-table-simple
                    borderless
                    responsive
                    class="particulars_of_deceased d-none d-md-block"
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Email Address
                        </b-th>
                        <b-td>{{ customerDetail.perEmail || '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Mobile No.
                        </b-th>
                        <b-td>{{ customerDetail.perContact || '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Home Phone No
                        </b-th>
                        <b-td>{{ customerDetail.perContactHome || '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Residential Address
                        </b-th>
                        <b-td>
                          <span>{{ customerDetail.perAddress1 }} {{ customerDetail.perAddress2 }}</span>
                          <br>
                          <span>{{ customerDetail.perCountry }} {{ customerDetail.perPostcode }}</span>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Mailing Address
                        </b-th>
                        <b-td>
                          <span>{{ customerDetail.corAddress1 }} {{ customerDetail.corAddress2 }}</span>
                          <br>
                          <span>{{ customerDetail.corCountry }} {{ customerDetail.corPostcode }}</span>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="canViewThisAction('read', 'File')">
        <b-col md="12">
          <b-card
            class="has-padding customer-files__card"
          >
            <b-card-title class="d-flex">
              <span>Customer’s Files</span>
            </b-card-title>
            <b-card-text>
              <b-tabs
                class="purchaser-tabs"
                content-class="mt-2"
              >
                <b-tab
                  title="As Purchaser"
                >
                  <AsPurchaserList
                    lazy
                    :purchaser-files="purchaserFiles"
                    :purchaser-role="customerDetail.entityID"
                  />
                </b-tab>
                <b-tab
                  lazy
                  title="As Intended User"
                >
                  <AsIntendedUserList
                    :purchaser-files="iuFiles"
                    :purchaser-role="customerDetail.entityID"
                  />
                </b-tab>
                <b-tab
                  lazy
                  title="As Next of Kin"
                >
                  <AsNextOfKinList
                    :purchaser-files="nokFiles"
                    :purchaser-role="customerDetail.entityID"
                  />
                </b-tab>
                <b-tab
                  lazy
                  title="As Addressee"
                >
                  <AsAdresseeList
                    :purchaser-files="addresseeFiles"
                    :purchaser-role="customerDetail.entityID"
                  />
                </b-tab>
              </b-tabs>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="12">
          <b-card
            class="has-padding customer-files__card"
          >
            <b-card-title class="d-flex">
              <span>Customer’s Relationships</span>
            </b-card-title>
            <b-card-text class="pt-2">
              <Relatives
                v-if="purchaserFiles.length"
                :purchaser-files="purchaserFiles"
                :purchaser="customerDetail.entityID"
              />
              <div
                v-else
                class="empty_block"
              >
                <h4>No data at the moment</h4>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Attachments"
      class="mb-3 mt-3 p-2"
    >
      <input
        ref="hiddenAllocationFileInput"
        type="file"
        multiple
        capture
        accept="image/jpeg, image/png, application/pdf"
        class="d-none"
        @change="setAttachment"
      >
      <b-row v-if="customerDetail.attachments && customerDetail.attachments.length">
        <!-- Content Left -->
        <b-col
          v-for="(file, key) in customerDetail.attachments"
          :key="key"
          class="content-header-left mb-2"
          cols="12"
          md="4"
        >
          <div
            class="attachment-item"
          >
            <b-img
              v-if="file.type.includes('image')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_jpg.png')"
              alt="Left image"
            />
            <b-img
              v-if="file.type.includes('application')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_file.png')"
              alt="Left image"
            />
            <strong>
              <b-link
                :href="file ? file.data : ''"
                target="_blank"
              >
                {{ file ? file.name : '' }}
              </b-link>
            </strong>
            <span>{{ file ? `${file.size}mb` : '' }}</span>
            <span class="remove__doc">
              <b-button
                v-if="canViewThisAction('update', 'Customer')"
                variant="flat-primary"
                @click="removeAttachment(file.name, file.data)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span>
          </div>
          <div class="doc__desc new_design">
            <span v-if="file.description">
              {{ file.description }}
            </span>
            <span v-else>
              <button
                v-if="canViewThisAction('update', 'Customer')"
                @click="updateDescription(key)"
              >
                Add a description
              </button>
            </span>
            <span
              v-if="file.description"
              class="edit__desc"
            >
              <b-button
                v-if="canViewThisAction('update', 'Customer')"
                variant="flat-primary"
                @click="updateDescription(key)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span>
          </div>
        </b-col>
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="4"
        >
          <b-button
            v-if="canViewThisAction('update', 'Customer')"
            variant="link"
            class="mr-1 md-mb-1"
            @click="$refs.hiddenAllocationFileInput.click()"
          >
            <feather-icon
              icon="UploadIcon"
              size="20"
              class="mr-50"
            />
            <span
              class="align-middle"
              style="font-weight: 500; font-size: 14px;"
            >
              Upload
            </span>
          </b-button>
          <b-button
            v-if="attachments.length"
            variant="success"
            class="mr-1 md-mb-1"
            @click="uploadFile"
          >
            Save File
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <div class="empty_block">
            <h4>Nothing in here yet</h4>
            <b-button
              v-if="canViewThisAction('update', 'Customer')"
              variant="link"
              class=""
              @click="$refs.hiddenAllocationFileInput.click()"
            >
              <feather-icon
                icon="UploadIcon"
                size="20"
                class="mr-50"
              />
              <span
                class="align-middle"
                style="font-weight: 500; font-size: 14px;"
              >
                Upload
              </span>
            </b-button>
            <b-button
              v-if="attachments.length"
              variant="success"
              class="mr-1 md-mb-1"
              @click="uploadFile"
            >
              Save File
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Related to this Customer"
      class="mb-3 mt-3 p-2"
    >
      <b-card-text>
        <b-tabs
          class="purchaser-tabs"
          content-class="mt-2"
        >
          <b-tab
            v-if="canViewThisAction('read', 'Invoice')"
            lazy
            title="Invoices"
          >
            <RelatedInvoices
              :related-invoices="invoices"
            />
          </b-tab>
          <b-tab
            v-if="canViewThisAction('read', 'Receipt')"
            lazy
            title="Receipts"
          >
            <ReceiptList
              :receipt-data="receipts"
            />
          </b-tab>
          <b-tab
            v-if="canViewThisAction('read', 'ServiceRequest')"
            lazy
            title="Service Requests"
          >
            <RelatedServiceRequests :service-requests="serviceRequests" />
          </b-tab>
          <b-tab
            v-if="canViewThisAction('read', 'InventoryRequest')"
            lazy
            title="Inventory Requests"
          >
            <RelatedInventoryRequests :inventory-requests="inventoryRequests" />
          </b-tab>
          <b-tab
            v-if="canViewThisAction('read', 'Booking')"
            lazy
            title="Appointments"
          >
            <RelatedAppointments :appointments="appointmentList" />
          </b-tab>
          <b-tab
            v-if="canViewThisAction('read', 'CallLog')"
            lazy
            title="Call Logs"
          >
            <RelatedCallLogs :call-logs="callLogs" />
          </b-tab>
        </b-tabs>
      </b-card-text>
    </b-card>

    <b-card
      class="mb-3 mt-3 p-2"
    >
      <b-row
        class="content-header"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left pb-2"
          cols="12"
          md="12"
        >
          <h4>
            <feather-icon
              icon="ListIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Activity Logs</span>
          </h4>
        </b-col>
      </b-row>
      <app-timeline>
        <!-- FINANCIAL REPORT -->
        <app-timeline-item
          v-for="(opt, key) in customerDetailActivityLogs"
          :key="key"
          :variant="getVariant(key)"
        >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ opt.title }}</h6>
            <small class="text-muted">{{ dateFormatWithTime(opt.time) }}</small>
          </div>
          <b-button
            v-if="opt.activityDetails && opt.activityDetails.length"
            v-b-toggle="'report-list-with-icon-' + key"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-primary"
          >
            Details
            <feather-icon
              icon="ChevronUpIcon"
              class="ml-50 when-open"
              size="16"
            />
            <feather-icon
              icon="ChevronDownIcon"
              class="ml-50 when-closed"
              size="16"
            />
          </b-button>
          <b-collapse
            v-if="opt.activityDetails && opt.activityDetails.length"
            :id="'report-list-with-icon-' + key"
          >
            <b-table-simple
              borderless
              responsive
            >
              <b-tbody>
                <b-tr
                  v-for="(activityDetail, activitykey) in opt.activityDetails"
                  :key="activitykey"
                >
                  <b-th style="width: 300px; max-width: 300px;">
                    {{ activityDetail.title }}
                  </b-th>
                  <b-td>{{ activityDetail.oldValue }}</b-td>
                  <b-td>{{ activityDetail.newValue }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-collapse>
        </app-timeline-item>
      </app-timeline>
    </b-card>

    <b-modal
      id="modal-attachment-description"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Payment Schedule"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, VBToggle, VBTooltip, BCollapse, BTab, BTabs,
  BCardTitle, BImg, BFormTextarea, BLink,
} from 'bootstrap-vue'
import store from '@/store/index'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RelatedInvoices from './RelatedInvoices.vue'
import ReceiptList from './ReceiptList.vue'
import RelatedServiceRequests from './RelatedServiceRequests.vue'
import RelatedInventoryRequests from './RelatedInventoryRequests.vue'
import RelatedAppointments from './RelatedAppointments.vue'
import RelatedCallLogs from './RelatedCallLogs.vue'
import AsPurchaserList from './AsPurchaserList.vue'
import AsIntendedUserList from './AsIntendedUserList.vue'
import AsNextOfKinList from './AsNextOfKinList.vue'
import AsAdresseeList from './AsAdresseeList.vue'
import Relatives from './Relatives.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCollapse,
    BTab,
    BTabs,
    BCardTitle,
    BImg,
    BFormTextarea,
    BLink,

    RelatedInvoices,
    ReceiptList,
    RelatedInventoryRequests,
    RelatedServiceRequests,
    RelatedAppointments,
    RelatedCallLogs,
    AsPurchaserList,
    AsIntendedUserList,
    AsNextOfKinList,
    AsAdresseeList,
    Relatives,
    AppTimeline,
    AppTimelineItem,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      customerDetail: {},
      invoices: [],
      receipts: [],
      appointmentList: [],
      serviceRequests: [],
      inventoryRequests: [],
      callLogs: [],
      purchaserFiles: [],
      iuFiles: [],
      nokFiles: [],
      addresseeFiles: [],
      customerDetailActivityLogs: [],
      required,
      attachments: [],
      description: '',
      attachmentKey: '',
      fileRemarks: '',
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 5,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        // maxFiles: 1,
      },
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.getCustomerDetail()
      },
    },
  },
  mounted() {
    window.scrollTo(0, 0)
    this.getCustomerDetail()
  },
  methods: {
    getCreatedBy() {
      if (this.customerDetail.createdByNvs) {
        return this.customerDetail.createdByNvs.name
      }
      if (this.customerDetail.createdBy) {
        return this.customerDetail.createdBy
      }

      return ''
    },
    getCreatedAt() {
      if (this.customerDetail.createdAtNvs) {
        return this.dateFormatWithTime(this.customerDetail.createdAtNvs)
      }
      if (this.customerDetail.createdBy) {
        return this.dateFormatWithFormatNTZ(this.customerDetail.createdAt, 'DD/MM/YYYY, hh:mma ')
      }

      return ''
    },
    getUpdatedAt() {
      if (this.customerDetail.updatedAtNvs) {
        return this.dateFormatWithTime(this.customerDetail.updatedAtNvs)
      }
      if (this.customerDetail.createdBy) {
        return this.dateFormatWithFormatNTZ(this.customerDetail.updatedAt, 'DD/MM/YYYY, hh:mma ')
      }

      return ''
    },
    getCustomerDetail() {
      this.$http.get(`customer/customers/${this.$route.params.id}/show`)
        .then(response => {
          if (response.data.success) {
            this.invoices = response.data.invoices
            this.receipts = response.data.receipts
            this.purchaserFiles = response.data.purchaserFiles
            this.iuFiles = response.data.iuFiles
            this.nokFiles = response.data.nokFiles
            this.addresseeFiles = response.data.addresseeFiles
            this.appointmentList = response.data.appointmentList
            this.serviceRequests = response.data.serviceRequests
            this.inventoryRequests = response.data.inventoryRequests
            this.callLogs = response.data.callLogs
            this.customerDetail = response.data.customer || {}
            const bcLength = store.state.breadcrumb.breadcrumbs.length
            if (bcLength > 1) {
              store.commit('breadcrumb/REMOVE_BREADCRUMB')
            }
            const newMeta = {
              active: true,
              text: `${this.customerDetail.stringID} - ${this.customerDetail.name}`,
            }
            store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
            this.customerDetailActivityLogs = this.customerDetail.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getVariant(key) {
      if (key % 4 === 0) return 'info'
      if (key % 4 === 1) return 'warning'
      if (key % 4 === 2) return 'danger'
      if (key % 4 === 3) return 'purple'
      return 'primary'
    },
    removeAttachment(name, url) {
      this.$http
        .post('customer/customers/attachment/remove', { url, name, id: this.customerDetail._id })
        .then(response => {
          this.customerDetail.attachments = response.data.data.attachments
          this.customerDetailActivityLogs = response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setAttachment(e) {
      if (e.target.files.length) {
        // const reader = new FileReader()
        // reader.readAsDataURL(this.file)
        // reader.onload = () => {
        //   e.target.filesrc = reader.result
        // }
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        e.target.files.forEach(element => {
          if (acceptedTypes.includes(element.type)) {
            if (element.size > 5 * 1024 * 1024) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Can't add files more than 5mb",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unsupported file type',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    uploadFile() {
      const formData = new FormData()
      formData.append('attachments', JSON.stringify(this.attachments))
      formData.append('id', this.customerDetail._id)
      this.$http.post('customer/customers/attachment/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.attachments = []
          this.customerDetail.attachments = response.data.data.attachments
          this.customerDetailActivityLogs = response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.customerDetail.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    saveDescription() {
      const url = this.customerDetail.attachments[this.attachmentKey].data
      this.$http
        .post('customer/customers/attachment/save-description', { url, description: this.description, id: this.customerDetail._id })
        .then(response => {
          this.customerDetail.attachments = response.data.data.attachments
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
  .timeline-variant-purple .timeline-item-point {
    background-color: #7367F0 !important;
  }
  .tab-parent .nav-item {
    margin-bottom: 0.75rem !important;
  }
</style>

<style lang="scss">
  @import "~@core/scss/base/pages/app-email.scss";
  .agent__details{
       background-color: #F8F8F8;
  }
  .content-body .card.customer-files__card th {
    padding: 11px 21px !important;
}
  .content-body .card.customer-files__card td {
    padding: 12px 21px !important;
}
</style>

<style scoped>
    .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
      border-right-width: 1px !important;
    }
    .dropzone-custom-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .dropzone-custom-title {
      margin-top: 0;
      color: #104D9D;
    }
    .subtitle {
      color: #333333;
    }
    .vs__dropdown-option--disabled {
      background: #ededed;
      color: #000000;
    }
    .sd-title {
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
    }
   .agent__media .media-body{
        display: flex;
        flex-direction: column;
        align-self: center;
   }

  </style>
