<template>
  <div>
    <b-table
      ref="refBillingDNTable"
      class="position-relative event_list min-height-200 shadow-table"
      :items="relatives"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(name)="data">
        <div class="text-nowrap">
          <b-button
            v-if="canViewThisAction('show', 'Customer') && data.item.customer"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="weight-600 align-middle detail-view-id"
            :to="{ name: 'customers-customers-show', params: { id: data.item.customer } }"
          >
            {{ data.item.name }}
          </b-button>
          <span v-else>{{ data.item.name }}</span>
          <br>
          <span>
            {{ data.item.relation }}
          </span>
        </div>
      </template>

      <template #cell(contact)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.contact }}</span>
          <br>
          <span>
            {{ data.item.email }}
          </span>
        </div>
      </template>

      <template #cell(fileNo)="data">
        <div class="text-nowrap">
          <b-button
            v-if="canViewThisAction('show', 'File')"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="text-bold align-middle detail-view-id"
            :to="{ name: 'customers-files-show', params: { id: data.item.fileID } }"
          >
            {{ data.item.fileNo }}
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="text-bold align-middle detail-view-id not-button"
          >
            {{ data.item.fileNo }}
          </b-button>
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(relations)="data">
        <p
          v-for="(item, key) in data.item.relations"
          :key="key"
          class="mb-0"
        >
          {{ item }}
        </p>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable, VBTooltip, BButton,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    purchaserFiles: {
      type: [Array, null],
      default: () => [],
    },
    purchaser: {
      type: [String, Number, null],
      default: null,
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'fileNo',
      sortDesc: false,
      isSortDirDesc: false,
      relatives: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      tableColumns: [
        {
          key: 'entityID',
          label: 'entity ID',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Name & relationship',
          sortable: true,
        },
        {
          key: 'contact',
          label: 'contact',
          sortable: true,
        },
        {
          key: 'fileNo',
          label: 'File no.',
          sortable: false,
        },
        {
          key: 'relations',
          label: 'nominee type',
          sortable: false,
        },
      ],
    }
  },

  created() {
    this.purchaserFiles.forEach(file => {
      file.intendedUsers.forEach(user => {
        if (user.entityID !== this.purchaser) {
          const exists = this.relatives.find(rel => rel.entityID === user.entityID && rel.fileID === file.fileID)
          if (exists) {
            exists.relations.push(`Intended User #${user.type.split('_').pop()}`)
          } else {
            const relativeObject = {
              name: user.name,
              entityID: user.entityID,
              nric: user.nric,
              relation: user.relation,
              contact: user.contact,
              email: user.email,
              customer: user.customer,
              relations: [`Intended User #${user.type.split('_').pop()}`],
              fileID: file.fileID,
              fileNo: file.fileNo,
            }

            this.relatives.push(relativeObject)
          }
        }
      })

      file.addressees.forEach(user => {
        if (user.entityID !== this.purchaser) {
          const exists = this.relatives.find(rel => rel.entityID === user.entityID && rel.fileID === file.fileID)
          if (exists) {
            exists.relations.push(`Addressee #${user.type.split('_').pop()}`)
          } else {
            const relativeObject = {
              name: user.name,
              entityID: user.entityID,
              nric: user.nric,
              relation: user.relation,
              contact: user.contact,
              email: user.email,
              customer: user.customer,
              relations: [`Addressee #${user.type.split('_').pop()}`],
              fileID: file.fileID,
              fileNo: file.fileNo,
            }

            this.relatives.push(relativeObject)
          }
        }
      })

      file.nextOfKins.forEach(user => {
        if (user.entityID !== this.purchaser) {
          const exists = this.relatives.find(rel => rel.entityID === user.entityID && rel.fileID === file.fileID)
          if (exists) {
            exists.relations.push(`Next of Kin #${user.type.split('_').pop()}`)
          } else {
            const relativeObject = {
              name: user.name,
              entityID: user.entityID,
              nric: user.nric,
              relation: user.relation,
              contact: user.contact,
              email: user.email,
              customer: user.customer,
              relations: [`Next of Kin #${user.type.split('_').pop()}`],
              fileID: file.fileID,
              fileNo: file.fileNo,
            }

            this.relatives.push(relativeObject)
          }
        }
      })
    })
  },

  methods: {
    canViewThisAction,
  },
}
</script>
