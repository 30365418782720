<template>
  <div>
    <b-table
      v-if="inventoryRequests.length"
      ref="refRelatedInventoryRequestsTable"
      class="position-relative event_list shadow-table"
      :class="{ low_items: inventoryRequests.length < 4 }"
      :items="inventoryRequests"
      responsive
      :fields="tableColumns"
      primary-key="_id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <!-- Column: Role -->
      <template #cell(stringID)="data">
        <div class="text-nowrap">
          <b-button
            v-if="canViewThisAction('show', 'InventoryRequest')"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="none"
            class="text-bold-black align-middle detail-view-id"
            :to="{ name: 'inventory-inventory-request-view', params: { id: data.item._id } }"
          >
            {{ data.item.stringID }}
            <br>
            <span class="font_normal">fr.</span>
            <b-button
              v-if="canViewThisAction('show', 'ServiceForm')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-primary align-middle detail-view-id"
              :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm } }"
            >
              {{ data.item.serviceFormStringID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-primary align-middle detail-view-id not-button"
            >
              {{ data.item.serviceFormStringID }}
            </b-button>
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="none"
            class="text-bold-black align-middle detail-view-id not-button"
          >
            {{ data.item.stringID }}
            <span class="font_normal">fr.</span>
            <b-button
              v-if="canViewThisAction('show', 'ServiceForm')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-primary align-middle detail-view-id"
              :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm } }"
            >
              {{ data.item.serviceFormStringID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-primary align-middle detail-view-id not-button"
            >
              {{ data.item.serviceFormStringID }}
            </b-button>
          </b-button>
        </div>
      </template>

      <template #cell(createdBy)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.createdBy.name }}</span>
        </div>
      </template>

      <template #cell(serviceDate)="data">
        <div class="text-nowrap">
          <span class="">{{ dateFormatWithTime(data.item.serviceDate) }}</span>
        </div>
      </template>

      <template #cell(customerName)="data">
        <div
          class="text-nowrap"
        >
          <span class="">{{ data.item.customerName }}</span>
          <br>
          <span
            v-if="data.item.fileNo"
            class=""
          >
            #{{ data.item.fileNo }}
          </span>
        </div>
      </template>

      <template #cell(stores)="data">
        <div class="">
          <span
            v-for="(option, indx) in data.item.stores"
            :key="indx"
          >
            {{ option.name }}<span v-if="indx + 1 != data.item.stores.length">, </span>
          </span>
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          :variant="`light-${resolveInventoryRequestStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ data.item.status }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template
        v-if="canViewThisAction('update-status', 'InventoryRequest') || canViewThisAction('show', 'InventoryRequest') || canViewThisAction('update', 'InventoryRequest')"
        #cell(actions)="data"
      >
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            v-if="canViewThisAction('show', 'InventoryRequest')"
            :to="{ name: 'inventory-inventory-request-view', params: { id: data.item._id } }"
          >
            <feather-icon icon="EyeIcon" />
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No inventory requests found.</h4>
    </div>
  </div>
</template>

<script>
import {
  BTable, BButton, VBTooltip, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    inventoryRequests: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'stringID',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      tableColumns: [
        {
          key: 'stringID',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'createdBy',
          label: 'Requester',
          sortable: true,
        },
        {
          key: 'serviceDate',
          label: 'Service request Date/time',
          sortable: true,
        },
        // {
        //   key: 'customerName',
        //   label: 'Customer name/File no.',
        //   sortable: true,
        // },
        {
          key: 'stores',
          label: 'Store(s)',
          sortable: true,
        },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],
    }
  },

  methods: {
    canViewThisAction,
    resolveInventoryRequestStatusVariant(status) {
      if (status === 'completed') return 'success'
      if (status === 'cancelled') return 'danger'
      if (status === 'returned') return 'warning'
      if (status === 'collected') return 'secondary'
      if (status === 'open') return 'info'
      return 'primary'
    },
  },
}
</script>
